
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonItem, IonInput, IonLabel, IonButton, toastController, IonSelect, IonSelectOption, IonIcon } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import { arrowBackOutline, arrowBackSharp } from 'ionicons/icons';

export default defineComponent({
  name: 'InseratCreateStep2',
  data () {
    return {
      dataFromPreviousStepIsCollected: false,
      inseratType: "directbuy",
      price: "",
      currency: "€",
      currencyOptions: ["€", "$", "CHF"],
      backIconIos: arrowBackOutline,
      backIconMd: arrowBackSharp
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonItem,
    IonInput,
    IonLabel,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonIcon
  },
  created() {

    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){
      // ready to create inserat
    } else {
      // push to main
      this.$router.push({ path: '/login' })
    }

    // check user permission
    if (!localStorage.userRoles.includes('ROLE_STAR')){
      this.$router.push({ path: '/login' })
    }

    // check if data is collected from previous steps
    if (!localStorage.inseratType){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratTitle){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratDescription){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratCategoryId){
      this.$router.push({ path: '/inserat/create' })
    }

    // check step
    if (localStorage.inseratStep !== 2){
      // redirect to right step
      if (localStorage.inseratStep == 0){
        this.$router.push({ path: '/inserat/create' });
      } else {
        this.$router.push({ path: '/inserat/create/step-' + localStorage.inseratStep });
      }
    }

    // get inserat type
    this.inseratType = localStorage.inseratType

  },
  methods: {
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    },
    goBackOneStep() {
        localStorage.inseratStep = 1;
        this.$router.push({ path: '/inserat/create/step-1' })
    },
    nextStep() {
      let errors = 0;

      // check inputs
      if (!this.price) {
        errors++;
        this.openToast("Du musst einen Preis angeben.", 2000);
      }

      if (!this.currency) {
        errors++;
        this.openToast("Du musst eine Währung angeben.", 2000);
      }

      if (this.currency !== "€" && this.currency !== "$" && this.currency !== "CHF"){
        errors++;
        this.openToast("Du musst eine gültige Währung angeben.", 2000);
      }

      if (errors == 0){
        // save in localStorage
        localStorage.inseratPrice = this.price;
        localStorage.inseratCurrency = this.currency;

        // go to next step
        localStorage.inseratStep = 3;
        this.$router.push({ path: '/inserat/create/step-3' })
      }

    }
  }
});

